import styled from "@emotion/styled";

export const SortButtonContainer = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 0;
`;
import styled from "@emotion/styled";

export const SortButtonContainer = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 0;
`;
