export const EMBED_MODAL_TABS = {
  Overview: "overview" as const,
  Parameters: "parameters" as const,
  LookAndFeel: "lookAndFeel" as const,
};
export const EMBED_MODAL_TABS = {
  Overview: "overview" as const,
  Parameters: "parameters" as const,
  LookAndFeel: "lookAndFeel" as const,
};
