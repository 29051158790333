import type { QueryClickActionsMode } from "../../types";

export const MetabotMode: QueryClickActionsMode = {
  name: "public",
  hasDrills: false,
  clickActions: [],
};
import type { QueryClickActionsMode } from "../../types";

export const MetabotMode: QueryClickActionsMode = {
  name: "public",
  hasDrills: false,
  clickActions: [],
};
