import styled from "@emotion/styled";

export const IconRoot = styled.svg`
  color: var(--mb-color-summarize);
`;
import styled from "@emotion/styled";

export const IconRoot = styled.svg`
  color: var(--mb-color-summarize);
`;
