import styled from "@emotion/styled";

export const ChartSettingsRoot = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
`;
import styled from "@emotion/styled";

export const ChartSettingsRoot = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
`;
