import styled from "@emotion/styled";

import { NumberInput } from "metabase/ui";

export const NumberInputStyled = styled(NumberInput)`
  input {
    text-align: center;
    padding: 0.25rem;
  }
`;
import styled from "@emotion/styled";

import { NumberInput } from "metabase/ui";

export const NumberInputStyled = styled(NumberInput)`
  input {
    text-align: center;
    padding: 0.25rem;
  }
`;
