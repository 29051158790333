import styled from "@emotion/styled";

export const SlackFormMessage = styled.div`
  color: var(--mb-color-text-medium);
  margin: 2rem 0 1rem;
`;
import styled from "@emotion/styled";

export const SlackFormMessage = styled.div`
  color: var(--mb-color-text-medium);
  margin: 2rem 0 1rem;
`;
