import styled from "@emotion/styled";

export const PasswordSuccessMessage = styled.div`
  padding-bottom: 4rem;
`;
import styled from "@emotion/styled";

export const PasswordSuccessMessage = styled.div`
  padding-bottom: 4rem;
`;
