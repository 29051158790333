import styled from "@emotion/styled";

export const ObjectDetailBodyWrapper = styled.div`
  font-size: 1.15em;
  flex: 1;
  overflow-y: auto;
`;
import styled from "@emotion/styled";

export const ObjectDetailBodyWrapper = styled.div`
  font-size: 1.15em;
  flex: 1;
  overflow-y: auto;
`;
