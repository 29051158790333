import styled from "@emotion/styled";

export const BodyRoot = styled.div`
  padding: 2rem;
`;
import styled from "@emotion/styled";

export const BodyRoot = styled.div`
  padding: 2rem;
`;
