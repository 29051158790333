import Modal from "metabase/components/Modal";
import Triggerable from "metabase/components/Triggerable";

/**
 * @deprecated use Modal + useState
 */
export default Triggerable(Modal);
import Modal from "metabase/components/Modal";
import Triggerable from "metabase/components/Triggerable";

/**
 * @deprecated use Modal + useState
 */
export default Triggerable(Modal);
