import cx from "classnames";
import PropTypes from "prop-types";
import { Component } from "react";

import CS from "metabase/css/core/index.css";
import { PLUGIN_LOGO_ICON_COMPONENTS } from "metabase/plugins";

class DefaultLogoIcon extends Component {
  static defaultProps = {
    height: 32,
  };
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    dark: PropTypes.bool,
    fill: PropTypes.string,
  };

  render() {
    const { dark, height, width, fill = "currentcolor" } = this.props;
    return (
      <svg
        className={cx(
          "Icon",
          { [CS.textBrand]: !dark },
          { [CS.textWhite]: dark },
        )}
        viewBox="0 0 340.000000 250.000000"
        width={width}
        height={height}
        fill={fill}
        data-testid="main-logo"
      >
        <defs>
          <linearGradient id="92" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#fa71cd" />
            <stop offset="100%" stopColor="#9b59b6" />
          </linearGradient>
          <linearGradient id="93" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#f9d423" />
            <stop offset="100%" stopColor="#f83600" />
          </linearGradient>
          <linearGradient id="94" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#0064d2" />
            <stop offset="100%" stopColor="#1cb0f6" />
          </linearGradient>
          <linearGradient id="95" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#f00978" />
            <stop offset="100%" stopColor="#3f51b1" />
          </linearGradient>
          <linearGradient id="96" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#7873f5" />
            <stop offset="100%" stopColor="#ec77ab" />
          </linearGradient>
          <linearGradient id="97" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#f9d423" />
            <stop offset="100%" stopColor="#e14fad" />
          </linearGradient>
          <linearGradient id="98" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#009efd" />
            <stop offset="100%" stopColor="#2af598" />
          </linearGradient>
          <linearGradient id="99" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#ffcc00" />
            <stop offset="100%" stopColor="#00b140" />
          </linearGradient>
          <linearGradient id="100" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#d51007" />
            <stop offset="100%" stopColor="#ff8177" />
          </linearGradient>
          <linearGradient id="102" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#a2b6df" />
            <stop offset="100%" stopColor="#0c3483" />
          </linearGradient>
          <linearGradient id="103" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#7ac5d8" />
            <stop offset="100%" stopColor="#eea2a2" />
          </linearGradient>
          <linearGradient id="104" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#00ecbc" />
            <stop offset="100%" stopColor="#007adf" />
          </linearGradient>
          <linearGradient id="105" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#b88746" />
            <stop offset="100%" stopColor="#fdf5a6" />
          </linearGradient>
        </defs>
        <g
          className="iconmbgsvg"
          transform="translate(61.25,1.6117782592773438)"
        >
          <g fill="#333" hollow-target="text">
            <g mask="url(#dceccf35-5b4d-4cdf-861e-f7dd5cbd8798)">
              <rect
                fill="#333"
                fillOpacity="0"
                strokeWidth="2"
                x="0"
                y="0"
                width="217.5"
                height="246.73308411214953"
                className="image-rect"
              />
              <svg
                x="0"
                y="0"
                width="217.5"
                height="246.73308411214953"
                filtersec="colorsb6398240753"
                className="image-svg-svg primary"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 401.25 455.18"
                >
                  <defs>
                    <linearGradient
                      id="ab93ca5e0-adea-403f-976e-9430d42eab32"
                      x1="1124.27"
                      y1="779"
                      x2="846.74"
                      y2="342.33"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="bfe2c5ac9-b028-4067-a7d9-394243c056ab"
                      x1="1125.45"
                      y1="778.24"
                      x2="847.93"
                      y2="341.57"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="c5418ed93-eb74-4f42-85b6-2220c9f3c136"
                      x1="1132.06"
                      y1="774.05"
                      x2="854.53"
                      y2="337.38"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="dc44b8900-9fa1-4aed-ad4a-7d214437f008"
                      x1="1173.07"
                      y1="747.98"
                      x2="895.54"
                      y2="311.31"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="e280748a7-f50b-4ee2-a9d4-3fa8bc977d79"
                      x1="1004.34"
                      y1="855.22"
                      x2="726.82"
                      y2="418.55"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="f14aed4a1-e5cc-4ba8-9a1c-fb6e9c081b96"
                      x1="1003.15"
                      y1="855.97"
                      x2="725.63"
                      y2="419.3"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="g2a629427-9fd0-4501-b242-7b21ab2a8ae5"
                      x1="996.55"
                      y1="860.17"
                      x2="719.03"
                      y2="423.5"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="h34275c3b-fb7e-4c0f-8ff7-00e7000355ea"
                      x1="955.54"
                      y1="886.23"
                      x2="678.02"
                      y2="449.56"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="ibeeaf9ed-4de8-4c46-a7a1-3f19a49dc9f1"
                      x1="1047.57"
                      y1="827.75"
                      x2="770.04"
                      y2="391.08"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="j144c78f7-517d-40dc-898b-e06f99208c31"
                      x1="1008.32"
                      y1="852.69"
                      x2="730.79"
                      y2="416.02"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="k63ab98a9-048b-4b48-85fa-03338ca4e446"
                      x1="970.63"
                      y1="876.64"
                      x2="693.11"
                      y2="439.97"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="la603b93d-9905-4b12-a9a5-9d03106621cf"
                      x1="1026.46"
                      y1="841.15"
                      x2="748.94"
                      y2="404.49"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="m176ab860-5f03-4d0c-91eb-7466bd944204"
                      x1="1081.04"
                      y1="806.47"
                      x2="803.52"
                      y2="369.8"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="ne2f1fd25-707b-4d07-8094-8787981958b8"
                      x1="1120.3"
                      y1="781.53"
                      x2="842.77"
                      y2="344.84"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="o574ccb4e-ad65-481c-bdaf-bbde48a2a016"
                      x1="1157.98"
                      y1="757.58"
                      x2="880.45"
                      y2="320.9"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="p970b5e48-dec1-4eae-9fb1-fd1a51d0ae3c"
                      x1="1102.15"
                      y1="793.06"
                      x2="824.61"
                      y2="356.38"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="qf2a7f529-feed-40eb-809c-129d39459d8d"
                      x1="989.6"
                      y1="864.58"
                      x2="712.08"
                      y2="427.91"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="rd9a98c62-fe62-4b2d-8cc5-90fd7eafc21d"
                      x1="947.17"
                      y1="891.57"
                      x2="669.63"
                      y2="454.88"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="s875f79ee-498c-40be-a2f3-52f81d90f328"
                      x1="904.4"
                      y1="918.74"
                      x2="626.87"
                      y2="482.07"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="t3e4bc1d9-85f2-4e58-a4c1-f844871758a2"
                      x1="917.7"
                      y1="910.28"
                      x2="640.18"
                      y2="473.61"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="uca688990-b501-469f-b04e-6a418407b6c3"
                      x1="1139.01"
                      y1="769.63"
                      x2="861.48"
                      y2="332.96"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="v52a00656-3054-44df-95ac-76894af6788d"
                      x1="1181.45"
                      y1="742.67"
                      x2="903.92"
                      y2="305.98"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="wc1cc6832-f4c3-49c0-8f5a-87a2a941f53d"
                      x1="1224.22"
                      y1="715.48"
                      x2="946.69"
                      y2="278.81"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="xe8ee7b19-8b02-4204-9302-2936d2f16030"
                      x1="1210.9"
                      y1="723.93"
                      x2="933.38"
                      y2="287.27"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                  </defs>
                  <path
                    d="M932.79 441.89v1.75c-.32 8.6-6.73 21.65-27.91 18.5a29.61 29.61 0 1 0 24.66 32.08c0-.08.58-9.67 5.29-17.11 3.62-5.72 10.23-9.93 21.59-9.39h1.23a24.88 24.88 0 1 0-24.86-25.84z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#ab93ca5e0-adea-403f-976e-9430d42eab32)"
                  />
                  <path
                    d="M916.93 420.46a18.87 18.87 0 1 0-18.86 18.87 18.87 18.87 0 0 0 18.86-18.87z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#bfe2c5ac9-b028-4067-a7d9-394243c056ab)"
                  />
                  <path
                    d="M854.88 409.6a22.41 22.41 0 0 0 22.3-20.23 34.36 34.36 0 0 1 2.94-11.12c2.38-4.66 6.73-8.61 14.84-8.94h3.48a18.82 18.82 0 1 0-18.8-19.55 2 2 0 0 0 0 .29v1.04c-.24 6.5-5.09 16.37-21.11 14a23.15 23.15 0 0 0-3.65-.3 22.4 22.4 0 1 0 0 44.8z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#c5418ed93-eb74-4f42-85b6-2220c9f3c136)"
                  />
                  <path
                    d="M941.53 399.08a15.42 15.42 0 1 0-15.42-15.41 15.41 15.41 0 0 0 15.42 15.41z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#dc44b8900-9fa1-4aed-ad4a-7d214437f008)"
                  />
                  <path
                    d="M868.09 676.67v-1.75c.31-8.6 6.72-21.65 27.9-18.5a30.76 30.76 0 0 0 4.83.39 29.62 29.62 0 1 0-29.48-32.48c-.05.08-.58 9.67-5.29 17.11-3.62 5.73-10.23 9.93-21.59 9.39h-1.23a24.88 24.88 0 1 0 24.85 25.84z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#e280748a7-f50b-4ee2-a9d4-3fa8bc977d79)"
                  />
                  <path
                    d="M884 698.11a18.87 18.87 0 1 0 18.87-18.87A18.87 18.87 0 0 0 884 698.11z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#f14aed4a1-e5cc-4ba8-9a1c-fb6e9c081b96)"
                  />
                  <path
                    d="M946 709a22.41 22.41 0 0 0-22.3 20.23 34.36 34.36 0 0 1-2.94 11.12c-2.38 4.66-6.73 8.61-14.85 8.94H902.44a18.82 18.82 0 1 0 18.8 19.54 2 2 0 0 0 0-.29v-.44-.6c.24-6.5 5.09-16.37 21.11-14a23 23 0 0 0 3.65.3 22.4 22.4 0 1 0 0-44.8z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#g2a629427-9fd0-4501-b242-7b21ab2a8ae5)"
                  />
                  <path
                    d="M859.36 719.48a15.42 15.42 0 1 0 15.41 15.41 15.42 15.42 0 0 0-15.41-15.41z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#h34275c3b-fb7e-4c0f-8ff7-00e7000355ea)"
                  />
                  <path
                    d="M815 472.57s1.28.76 1.51.88c7.29 4.57 15.39 16.65 2.07 33.42a29.62 29.62 0 1 0 40.12-5.31c0-.08-8.08-5.34-12.18-13.14-3.14-6-3.48-13.82 2.67-23.39l.61-1.07a24.88 24.88 0 1 0-34.8 8.61z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#ibeeaf9ed-4de8-4c46-a7a1-3f19a49dc9f1)"
                  />
                  <path
                    d="M762.69 482.49a18.87 18.87 0 1 0 25.77-6.9 18.88 18.88 0 0 0-25.77 6.9z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#j144c78f7-517d-40dc-898b-e06f99208c31)"
                  />
                  <path
                    d="M708.61 472.55l.24.15.38.23.53.29c5.51 3.45 11.64 12.59 1.56 25.27a22.4 22.4 0 1 0 30.34-4 35 35 0 0 1-8.16-8.11c-2.84-4.39-4.09-10.13-.31-17.33.37-.72 1.25-2.2 1.25-2.2.17-.26.34-.53.5-.8a18.82 18.82 0 1 0-26.33 6.51z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#k63ab98a9-048b-4b48-85fa-03338ca4e446)"
                  />
                  <path
                    d="M761.19 449.24a15.41 15.41 0 1 0-5.64-21.05 15.41 15.41 0 0 0 5.64 21.05z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#la603b93d-9905-4b12-a9a5-9d03106621cf)"
                  />
                  <path
                    d="M985.93 646l-1.52-.88c-7.28-4.57-15.38-16.65-2.06-33.41a29 29 0 0 0 2.76-4 29.62 29.62 0 1 0-42.88 9.29c0 .08 8.08 5.34 12.17 13.14 3.15 6 3.49 13.82-2.66 23.39l-.62 1.07a24.88 24.88 0 1 0 34.81-8.6z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#m176ab860-5f03-4d0c-91eb-7466bd944204)"
                  />
                  <path
                    d="M1038.2 636.07a18.87 18.87 0 1 0-25.78 6.9 18.87 18.87 0 0 0 25.78-6.9z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#ne2f1fd25-707b-4d07-8094-8787981958b8)"
                  />
                  <path
                    d="M1092.28 646l-.25-.15-.37-.23-.53-.28c-5.51-3.46-11.64-12.59-1.56-25.28a22.4 22.4 0 1 0-30.34 4 34.52 34.52 0 0 1 8.15 8.1c2.85 4.39 4.09 10.13.32 17.33-.38.72-1.26 2.2-1.26 2.2-.17.26-.33.53-.49.8a18.82 18.82 0 1 0 26.33-6.51z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#o574ccb4e-ad65-481c-bdaf-bbde48a2a016)"
                  />
                  <path
                    d="M1039.69 669.32a15.41 15.41 0 1 0 5.64 21 15.42 15.42 0 0 0-5.64-21z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#p970b5e48-dec1-4eae-9fb1-fd1a51d0ae3c)"
                  />
                  <path
                    d="M856.63 619.22a29.62 29.62 0 0 0-27.07-52.61c-.09 0-8.66 4.33-17.46 4-6.77-.27-13.72-3.89-18.93-14l-.61-1.07a24.89 24.89 0 1 0-9.95 34.46l1.52-.87c7.6-4 22.11-5 30 14.91a29.61 29.61 0 0 0 42.53 15.22z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#qf2a7f529-feed-40eb-809c-129d39459d8d)"
                  />
                  <path
                    d="M772 614.41a18.87 18.87 0 1 0 25.78 6.91 18.88 18.88 0 0 0-25.78-6.91z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#rd9a98c62-fe62-4b2d-8cc5-90fd7eafc21d)"
                  />
                  <path
                    d="M764.93 664.39a34.45 34.45 0 0 1-11.1 3c-5.23.27-10.82-1.52-15.16-8.39-.44-.68-1.28-2.19-1.28-2.19-.14-.28-.29-.55-.45-.83a18.82 18.82 0 1 0-7.52 26.06l.25-.14.38-.21.52-.31c5.74-3 16.72-3.79 22.66 11.28a22.4 22.4 0 1 0 11.69-28.28z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#s875f79ee-498c-40be-a2f3-52f81d90f328)"
                  />
                  <path
                    d="M720.11 598.16a15.42 15.42 0 1 0 21.06 5.64 15.4 15.4 0 0 0-21.06-5.64z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#t3e4bc1d9-85f2-4e58-a4c1-f844871758a2)"
                  />
                  <path
                    d="M1008.33 563.05a24.88 24.88 0 1 0 9.95-34.45l-1.52.87c-7.6 4-22.11 5-30-14.91A29.62 29.62 0 1 0 971.32 552c.1 0 8.67-4.33 17.47-4 6.77.27 13.71 3.9 18.92 14z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#uca688990-b501-469f-b04e-6a418407b6c3)"
                  />
                  <path
                    d="M1028.91 504.15a18.87 18.87 0 1 0-25.77-6.91 18.87 18.87 0 0 0 25.77 6.91z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#v52a00656-3054-44df-95ac-76894af6788d)"
                  />
                  <path
                    d="M1036 454.17a34.63 34.63 0 0 1 11.1-3c5.22-.27 10.82 1.52 15.16 8.39.43.69 1.28 2.19 1.28 2.19.14.28.29.55.45.83a18.82 18.82 0 1 0 7.52-26.05l-.25.13-.39.21-.51.31c-5.75 3.05-16.72 3.79-22.67-11.28a21.66 21.66 0 0 0-1.57-3.31 22.4 22.4 0 1 0-10.12 31.58z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#wc1cc6832-f4c3-49c0-8f5a-87a2a941f53d)"
                  />
                  <path
                    d="M1065.36 493.71a15.41 15.41 0 1 0 21.06 5.64 15.4 15.4 0 0 0-21.06-5.64z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#xe8ee7b19-8b02-4204-9302-2936d2f16030)"
                  />
                </svg>
              </svg>
            </g>
            <g transform="translate(40.209999084472656,101.70822143554688)">
              <g fillRule="" className="tp-name">
                <g>
                  <path
                    d="M28.47-15.15L13.65-15.15L13.65 0L4.42 0L4.42-43.36L26.07-43.36Q31.98-43.36 34.52-42.51L34.52-42.51Q38.80-41.08 41.27-36.59L41.27-36.59Q43.09-33.22 43.09-29.25L43.09-29.25Q43.09-25.94 41.89-22.98Q40.69-20.02 38.55-18.14L38.55-18.14Q36.59-16.45 34.42-15.79Q32.24-15.15 28.47-15.15L28.47-15.15ZM13.65-35.62L13.65-22.88L26.20-22.88Q29.57-22.88 30.94-23.92L30.94-23.92Q32.95-25.55 32.95-29.57L32.95-29.57Q32.95-33.93 29.90-35.16L29.90-35.16Q28.80-35.62 26.39-35.62L26.39-35.62L13.65-35.62ZM58.56 0L58.56-35.62L44.78-35.62L44.78-43.36L82.16-43.36L82.16-35.62L67.80-35.62L67.80 0L58.56 0ZM110.89 0L86.91 0L86.91-43.36L111.41-43.36Q117.19-43.36 119.80-41.99L119.80-41.99Q122.85-40.50 124.41-36.98L124.41-36.98Q125.52-34.58 125.52-31.98L125.52-31.98Q125.52-28.60 123.73-25.68Q121.94-22.75 119.21-21.64L119.21-21.64Q125.52-18.79 125.52-11.44L125.52-11.44Q125.52-7.21 123.50-4.36L123.50-4.36Q121.75-1.89 119.02-0.94Q116.28 0 110.89 0L110.89 0ZM96.14-35.62L96.14-25.55L108.68-25.55Q110.69-25.55 111.57-25.74Q112.45-25.94 113.30-26.59L113.30-26.59Q115.31-28.14 115.31-30.88L115.31-30.88Q115.31-33.48 113.42-34.71L113.42-34.71Q112.06-35.62 108.88-35.62L108.88-35.62L96.14-35.62ZM96.14-17.81L96.14-7.74L108.68-7.74Q110.69-7.74 111.61-7.93Q112.52-8.13 113.30-8.78L113.30-8.78Q115.31-10.34 115.31-13.07L115.31-13.07Q115.31-15.67 113.42-16.90L113.42-16.90Q112.06-17.81 108.88-17.81L108.88-17.81L96.14-17.81ZM141.50 0L132.28 0L132.28-43.36L141.50-43.36L141.50 0Z"
                    transform="translate(-4.42, 43.355000000000004)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <mask id="dceccf35-5b4d-4cdf-861e-f7dd5cbd8798">
          <g fill="white">
            <rect
              fillOpacity="1"
              strokeWidth="2"
              x="0"
              y="0"
              width="217.5"
              height="246.73308411214953"
              className="image-rect"
            />
            <svg
              x="0"
              y="0"
              width="217.5"
              height="246.73308411214953"
              filtersec="colorsb6398240753"
              className="image-svg-svg primary"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 401.25 455.18"
              >
                <defs>
                  <linearGradient
                    id="ab93ca5e0-adea-403f-976e-9430d42eab32"
                    x1="1124.27"
                    y1="779"
                    x2="846.74"
                    y2="342.33"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="bfe2c5ac9-b028-4067-a7d9-394243c056ab"
                    x1="1125.45"
                    y1="778.24"
                    x2="847.93"
                    y2="341.57"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="c5418ed93-eb74-4f42-85b6-2220c9f3c136"
                    x1="1132.06"
                    y1="774.05"
                    x2="854.53"
                    y2="337.38"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="dc44b8900-9fa1-4aed-ad4a-7d214437f008"
                    x1="1173.07"
                    y1="747.98"
                    x2="895.54"
                    y2="311.31"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="e280748a7-f50b-4ee2-a9d4-3fa8bc977d79"
                    x1="1004.34"
                    y1="855.22"
                    x2="726.82"
                    y2="418.55"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="f14aed4a1-e5cc-4ba8-9a1c-fb6e9c081b96"
                    x1="1003.15"
                    y1="855.97"
                    x2="725.63"
                    y2="419.3"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="g2a629427-9fd0-4501-b242-7b21ab2a8ae5"
                    x1="996.55"
                    y1="860.17"
                    x2="719.03"
                    y2="423.5"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="h34275c3b-fb7e-4c0f-8ff7-00e7000355ea"
                    x1="955.54"
                    y1="886.23"
                    x2="678.02"
                    y2="449.56"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="ibeeaf9ed-4de8-4c46-a7a1-3f19a49dc9f1"
                    x1="1047.57"
                    y1="827.75"
                    x2="770.04"
                    y2="391.08"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="j144c78f7-517d-40dc-898b-e06f99208c31"
                    x1="1008.32"
                    y1="852.69"
                    x2="730.79"
                    y2="416.02"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="k63ab98a9-048b-4b48-85fa-03338ca4e446"
                    x1="970.63"
                    y1="876.64"
                    x2="693.11"
                    y2="439.97"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="la603b93d-9905-4b12-a9a5-9d03106621cf"
                    x1="1026.46"
                    y1="841.15"
                    x2="748.94"
                    y2="404.49"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="m176ab860-5f03-4d0c-91eb-7466bd944204"
                    x1="1081.04"
                    y1="806.47"
                    x2="803.52"
                    y2="369.8"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="ne2f1fd25-707b-4d07-8094-8787981958b8"
                    x1="1120.3"
                    y1="781.53"
                    x2="842.77"
                    y2="344.84"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="o574ccb4e-ad65-481c-bdaf-bbde48a2a016"
                    x1="1157.98"
                    y1="757.58"
                    x2="880.45"
                    y2="320.9"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="p970b5e48-dec1-4eae-9fb1-fd1a51d0ae3c"
                    x1="1102.15"
                    y1="793.06"
                    x2="824.61"
                    y2="356.38"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="qf2a7f529-feed-40eb-809c-129d39459d8d"
                    x1="989.6"
                    y1="864.58"
                    x2="712.08"
                    y2="427.91"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="rd9a98c62-fe62-4b2d-8cc5-90fd7eafc21d"
                    x1="947.17"
                    y1="891.57"
                    x2="669.63"
                    y2="454.88"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="s875f79ee-498c-40be-a2f3-52f81d90f328"
                    x1="904.4"
                    y1="918.74"
                    x2="626.87"
                    y2="482.07"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="t3e4bc1d9-85f2-4e58-a4c1-f844871758a2"
                    x1="917.7"
                    y1="910.28"
                    x2="640.18"
                    y2="473.61"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="uca688990-b501-469f-b04e-6a418407b6c3"
                    x1="1139.01"
                    y1="769.63"
                    x2="861.48"
                    y2="332.96"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="v52a00656-3054-44df-95ac-76894af6788d"
                    x1="1181.45"
                    y1="742.67"
                    x2="903.92"
                    y2="305.98"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="wc1cc6832-f4c3-49c0-8f5a-87a2a941f53d"
                    x1="1224.22"
                    y1="715.48"
                    x2="946.69"
                    y2="278.81"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="xe8ee7b19-8b02-4204-9302-2936d2f16030"
                    x1="1210.9"
                    y1="723.93"
                    x2="933.38"
                    y2="287.27"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                </defs>
                <path
                  d="M932.79 441.89v1.75c-.32 8.6-6.73 21.65-27.91 18.5a29.61 29.61 0 1 0 24.66 32.08c0-.08.58-9.67 5.29-17.11 3.62-5.72 10.23-9.93 21.59-9.39h1.23a24.88 24.88 0 1 0-24.86-25.84z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M916.93 420.46a18.87 18.87 0 1 0-18.86 18.87 18.87 18.87 0 0 0 18.86-18.87z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M854.88 409.6a22.41 22.41 0 0 0 22.3-20.23 34.36 34.36 0 0 1 2.94-11.12c2.38-4.66 6.73-8.61 14.84-8.94h3.48a18.82 18.82 0 1 0-18.8-19.55 2 2 0 0 0 0 .29v1.04c-.24 6.5-5.09 16.37-21.11 14a23.15 23.15 0 0 0-3.65-.3 22.4 22.4 0 1 0 0 44.8z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M941.53 399.08a15.42 15.42 0 1 0-15.42-15.41 15.41 15.41 0 0 0 15.42 15.41z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M868.09 676.67v-1.75c.31-8.6 6.72-21.65 27.9-18.5a30.76 30.76 0 0 0 4.83.39 29.62 29.62 0 1 0-29.48-32.48c-.05.08-.58 9.67-5.29 17.11-3.62 5.73-10.23 9.93-21.59 9.39h-1.23a24.88 24.88 0 1 0 24.85 25.84z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M884 698.11a18.87 18.87 0 1 0 18.87-18.87A18.87 18.87 0 0 0 884 698.11z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M946 709a22.41 22.41 0 0 0-22.3 20.23 34.36 34.36 0 0 1-2.94 11.12c-2.38 4.66-6.73 8.61-14.85 8.94H902.44a18.82 18.82 0 1 0 18.8 19.54 2 2 0 0 0 0-.29v-.44-.6c.24-6.5 5.09-16.37 21.11-14a23 23 0 0 0 3.65.3 22.4 22.4 0 1 0 0-44.8z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M859.36 719.48a15.42 15.42 0 1 0 15.41 15.41 15.42 15.42 0 0 0-15.41-15.41z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M815 472.57s1.28.76 1.51.88c7.29 4.57 15.39 16.65 2.07 33.42a29.62 29.62 0 1 0 40.12-5.31c0-.08-8.08-5.34-12.18-13.14-3.14-6-3.48-13.82 2.67-23.39l.61-1.07a24.88 24.88 0 1 0-34.8 8.61z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M762.69 482.49a18.87 18.87 0 1 0 25.77-6.9 18.88 18.88 0 0 0-25.77 6.9z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M708.61 472.55l.24.15.38.23.53.29c5.51 3.45 11.64 12.59 1.56 25.27a22.4 22.4 0 1 0 30.34-4 35 35 0 0 1-8.16-8.11c-2.84-4.39-4.09-10.13-.31-17.33.37-.72 1.25-2.2 1.25-2.2.17-.26.34-.53.5-.8a18.82 18.82 0 1 0-26.33 6.51z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M761.19 449.24a15.41 15.41 0 1 0-5.64-21.05 15.41 15.41 0 0 0 5.64 21.05z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M985.93 646l-1.52-.88c-7.28-4.57-15.38-16.65-2.06-33.41a29 29 0 0 0 2.76-4 29.62 29.62 0 1 0-42.88 9.29c0 .08 8.08 5.34 12.17 13.14 3.15 6 3.49 13.82-2.66 23.39l-.62 1.07a24.88 24.88 0 1 0 34.81-8.6z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M1038.2 636.07a18.87 18.87 0 1 0-25.78 6.9 18.87 18.87 0 0 0 25.78-6.9z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M1092.28 646l-.25-.15-.37-.23-.53-.28c-5.51-3.46-11.64-12.59-1.56-25.28a22.4 22.4 0 1 0-30.34 4 34.52 34.52 0 0 1 8.15 8.1c2.85 4.39 4.09 10.13.32 17.33-.38.72-1.26 2.2-1.26 2.2-.17.26-.33.53-.49.8a18.82 18.82 0 1 0 26.33-6.51z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M1039.69 669.32a15.41 15.41 0 1 0 5.64 21 15.42 15.42 0 0 0-5.64-21z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M856.63 619.22a29.62 29.62 0 0 0-27.07-52.61c-.09 0-8.66 4.33-17.46 4-6.77-.27-13.72-3.89-18.93-14l-.61-1.07a24.89 24.89 0 1 0-9.95 34.46l1.52-.87c7.6-4 22.11-5 30 14.91a29.61 29.61 0 0 0 42.53 15.22z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M772 614.41a18.87 18.87 0 1 0 25.78 6.91 18.88 18.88 0 0 0-25.78-6.91z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M764.93 664.39a34.45 34.45 0 0 1-11.1 3c-5.23.27-10.82-1.52-15.16-8.39-.44-.68-1.28-2.19-1.28-2.19-.14-.28-.29-.55-.45-.83a18.82 18.82 0 1 0-7.52 26.06l.25-.14.38-.21.52-.31c5.74-3 16.72-3.79 22.66 11.28a22.4 22.4 0 1 0 11.69-28.28z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M720.11 598.16a15.42 15.42 0 1 0 21.06 5.64 15.4 15.4 0 0 0-21.06-5.64z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M1008.33 563.05a24.88 24.88 0 1 0 9.95-34.45l-1.52.87c-7.6 4-22.11 5-30-14.91A29.62 29.62 0 1 0 971.32 552c.1 0 8.67-4.33 17.47-4 6.77.27 13.71 3.9 18.92 14z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M1028.91 504.15a18.87 18.87 0 1 0-25.77-6.91 18.87 18.87 0 0 0 25.77 6.91z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M1036 454.17a34.63 34.63 0 0 1 11.1-3c5.22-.27 10.82 1.52 15.16 8.39.43.69 1.28 2.19 1.28 2.19.14.28.29.55.45.83a18.82 18.82 0 1 0 7.52-26.05l-.25.13-.39.21-.51.31c-5.75 3.05-16.72 3.79-22.67-11.28a21.66 21.66 0 0 0-1.57-3.31 22.4 22.4 0 1 0-10.12 31.58z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M1065.36 493.71a15.41 15.41 0 1 0 21.06 5.64 15.4 15.4 0 0 0-21.06-5.64z"
                  transform="translate(-699.82 -331.69)"
                />
              </svg>
            </svg>
          </g>
          <g
            transform="translate(40.209999084472656,101.70822143554688)"
            fill="black"
            stroke="#000"
            strokeWidth="6"
            strokeLinejoin="round"
          >
            <g fillRule="" className="tp-name">
              <g>
                <path
                  d="M28.47-15.15L13.65-15.15L13.65 0L4.42 0L4.42-43.36L26.07-43.36Q31.98-43.36 34.52-42.51L34.52-42.51Q38.80-41.08 41.27-36.59L41.27-36.59Q43.09-33.22 43.09-29.25L43.09-29.25Q43.09-25.94 41.89-22.98Q40.69-20.02 38.55-18.14L38.55-18.14Q36.59-16.45 34.42-15.79Q32.24-15.15 28.47-15.15L28.47-15.15ZM13.65-35.62L13.65-22.88L26.20-22.88Q29.57-22.88 30.94-23.92L30.94-23.92Q32.95-25.55 32.95-29.57L32.95-29.57Q32.95-33.93 29.90-35.16L29.90-35.16Q28.80-35.62 26.39-35.62L26.39-35.62L13.65-35.62ZM58.56 0L58.56-35.62L44.78-35.62L44.78-43.36L82.16-43.36L82.16-35.62L67.80-35.62L67.80 0L58.56 0ZM110.89 0L86.91 0L86.91-43.36L111.41-43.36Q117.19-43.36 119.80-41.99L119.80-41.99Q122.85-40.50 124.41-36.98L124.41-36.98Q125.52-34.58 125.52-31.98L125.52-31.98Q125.52-28.60 123.73-25.68Q121.94-22.75 119.21-21.64L119.21-21.64Q125.52-18.79 125.52-11.44L125.52-11.44Q125.52-7.21 123.50-4.36L123.50-4.36Q121.75-1.89 119.02-0.94Q116.28 0 110.89 0L110.89 0ZM96.14-35.62L96.14-25.55L108.68-25.55Q110.69-25.55 111.57-25.74Q112.45-25.94 113.30-26.59L113.30-26.59Q115.31-28.14 115.31-30.88L115.31-30.88Q115.31-33.48 113.42-34.71L113.42-34.71Q112.06-35.62 108.88-35.62L108.88-35.62L96.14-35.62ZM96.14-17.81L96.14-7.74L108.68-7.74Q110.69-7.74 111.61-7.93Q112.52-8.13 113.30-8.78L113.30-8.78Q115.31-10.34 115.31-13.07L115.31-13.07Q115.31-15.67 113.42-16.90L113.42-16.90Q112.06-17.81 108.88-17.81L108.88-17.81L96.14-17.81ZM141.50 0L132.28 0L132.28-43.36L141.50-43.36L141.50 0Z"
                  transform="translate(-4.42, 43.355000000000004)"
                />
              </g>
            </g>
          </g>
        </mask>
      </svg>
    );
  }
}

export default function LogoIcon(props) {
  const [Component = DefaultLogoIcon] = PLUGIN_LOGO_ICON_COMPONENTS;
  return <Component {...props} />;
}
import cx from "classnames";
import PropTypes from "prop-types";
import { Component } from "react";

import CS from "metabase/css/core/index.css";
import { PLUGIN_LOGO_ICON_COMPONENTS } from "metabase/plugins";

class DefaultLogoIcon extends Component {
  static defaultProps = {
    height: 32,
  };
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    dark: PropTypes.bool,
    fill: PropTypes.string,
  };

  render() {
    const { dark, height, width, fill = "currentcolor" } = this.props;
    return (
      <svg
        className={cx(
          "Icon",
          { [CS.textBrand]: !dark },
          { [CS.textWhite]: dark },
        )}
        viewBox="0 0 340.000000 250.000000"
        width={width}
        height={height}
        fill={fill}
        data-testid="main-logo"
      >
        <defs>
          <linearGradient id="92" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#fa71cd" />
            <stop offset="100%" stopColor="#9b59b6" />
          </linearGradient>
          <linearGradient id="93" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#f9d423" />
            <stop offset="100%" stopColor="#f83600" />
          </linearGradient>
          <linearGradient id="94" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#0064d2" />
            <stop offset="100%" stopColor="#1cb0f6" />
          </linearGradient>
          <linearGradient id="95" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#f00978" />
            <stop offset="100%" stopColor="#3f51b1" />
          </linearGradient>
          <linearGradient id="96" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#7873f5" />
            <stop offset="100%" stopColor="#ec77ab" />
          </linearGradient>
          <linearGradient id="97" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#f9d423" />
            <stop offset="100%" stopColor="#e14fad" />
          </linearGradient>
          <linearGradient id="98" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#009efd" />
            <stop offset="100%" stopColor="#2af598" />
          </linearGradient>
          <linearGradient id="99" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#ffcc00" />
            <stop offset="100%" stopColor="#00b140" />
          </linearGradient>
          <linearGradient id="100" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#d51007" />
            <stop offset="100%" stopColor="#ff8177" />
          </linearGradient>
          <linearGradient id="102" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#a2b6df" />
            <stop offset="100%" stopColor="#0c3483" />
          </linearGradient>
          <linearGradient id="103" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#7ac5d8" />
            <stop offset="100%" stopColor="#eea2a2" />
          </linearGradient>
          <linearGradient id="104" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#00ecbc" />
            <stop offset="100%" stopColor="#007adf" />
          </linearGradient>
          <linearGradient id="105" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#b88746" />
            <stop offset="100%" stopColor="#fdf5a6" />
          </linearGradient>
        </defs>
        <g
          className="iconmbgsvg"
          transform="translate(61.25,1.6117782592773438)"
        >
          <g fill="#333" hollow-target="text">
            <g mask="url(#dceccf35-5b4d-4cdf-861e-f7dd5cbd8798)">
              <rect
                fill="#333"
                fillOpacity="0"
                strokeWidth="2"
                x="0"
                y="0"
                width="217.5"
                height="246.73308411214953"
                className="image-rect"
              />
              <svg
                x="0"
                y="0"
                width="217.5"
                height="246.73308411214953"
                filtersec="colorsb6398240753"
                className="image-svg-svg primary"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 401.25 455.18"
                >
                  <defs>
                    <linearGradient
                      id="ab93ca5e0-adea-403f-976e-9430d42eab32"
                      x1="1124.27"
                      y1="779"
                      x2="846.74"
                      y2="342.33"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="bfe2c5ac9-b028-4067-a7d9-394243c056ab"
                      x1="1125.45"
                      y1="778.24"
                      x2="847.93"
                      y2="341.57"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="c5418ed93-eb74-4f42-85b6-2220c9f3c136"
                      x1="1132.06"
                      y1="774.05"
                      x2="854.53"
                      y2="337.38"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="dc44b8900-9fa1-4aed-ad4a-7d214437f008"
                      x1="1173.07"
                      y1="747.98"
                      x2="895.54"
                      y2="311.31"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="e280748a7-f50b-4ee2-a9d4-3fa8bc977d79"
                      x1="1004.34"
                      y1="855.22"
                      x2="726.82"
                      y2="418.55"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="f14aed4a1-e5cc-4ba8-9a1c-fb6e9c081b96"
                      x1="1003.15"
                      y1="855.97"
                      x2="725.63"
                      y2="419.3"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="g2a629427-9fd0-4501-b242-7b21ab2a8ae5"
                      x1="996.55"
                      y1="860.17"
                      x2="719.03"
                      y2="423.5"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="h34275c3b-fb7e-4c0f-8ff7-00e7000355ea"
                      x1="955.54"
                      y1="886.23"
                      x2="678.02"
                      y2="449.56"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="ibeeaf9ed-4de8-4c46-a7a1-3f19a49dc9f1"
                      x1="1047.57"
                      y1="827.75"
                      x2="770.04"
                      y2="391.08"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="j144c78f7-517d-40dc-898b-e06f99208c31"
                      x1="1008.32"
                      y1="852.69"
                      x2="730.79"
                      y2="416.02"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="k63ab98a9-048b-4b48-85fa-03338ca4e446"
                      x1="970.63"
                      y1="876.64"
                      x2="693.11"
                      y2="439.97"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="la603b93d-9905-4b12-a9a5-9d03106621cf"
                      x1="1026.46"
                      y1="841.15"
                      x2="748.94"
                      y2="404.49"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="m176ab860-5f03-4d0c-91eb-7466bd944204"
                      x1="1081.04"
                      y1="806.47"
                      x2="803.52"
                      y2="369.8"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="ne2f1fd25-707b-4d07-8094-8787981958b8"
                      x1="1120.3"
                      y1="781.53"
                      x2="842.77"
                      y2="344.84"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="o574ccb4e-ad65-481c-bdaf-bbde48a2a016"
                      x1="1157.98"
                      y1="757.58"
                      x2="880.45"
                      y2="320.9"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="p970b5e48-dec1-4eae-9fb1-fd1a51d0ae3c"
                      x1="1102.15"
                      y1="793.06"
                      x2="824.61"
                      y2="356.38"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="qf2a7f529-feed-40eb-809c-129d39459d8d"
                      x1="989.6"
                      y1="864.58"
                      x2="712.08"
                      y2="427.91"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="rd9a98c62-fe62-4b2d-8cc5-90fd7eafc21d"
                      x1="947.17"
                      y1="891.57"
                      x2="669.63"
                      y2="454.88"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="s875f79ee-498c-40be-a2f3-52f81d90f328"
                      x1="904.4"
                      y1="918.74"
                      x2="626.87"
                      y2="482.07"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="t3e4bc1d9-85f2-4e58-a4c1-f844871758a2"
                      x1="917.7"
                      y1="910.28"
                      x2="640.18"
                      y2="473.61"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="uca688990-b501-469f-b04e-6a418407b6c3"
                      x1="1139.01"
                      y1="769.63"
                      x2="861.48"
                      y2="332.96"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="v52a00656-3054-44df-95ac-76894af6788d"
                      x1="1181.45"
                      y1="742.67"
                      x2="903.92"
                      y2="305.98"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="wc1cc6832-f4c3-49c0-8f5a-87a2a941f53d"
                      x1="1224.22"
                      y1="715.48"
                      x2="946.69"
                      y2="278.81"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                    <linearGradient
                      id="xe8ee7b19-8b02-4204-9302-2936d2f16030"
                      x1="1210.9"
                      y1="723.93"
                      x2="933.38"
                      y2="287.27"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#cce70b" />
                      <stop offset="1" stopColor="#00bcc6" />
                    </linearGradient>
                  </defs>
                  <path
                    d="M932.79 441.89v1.75c-.32 8.6-6.73 21.65-27.91 18.5a29.61 29.61 0 1 0 24.66 32.08c0-.08.58-9.67 5.29-17.11 3.62-5.72 10.23-9.93 21.59-9.39h1.23a24.88 24.88 0 1 0-24.86-25.84z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#ab93ca5e0-adea-403f-976e-9430d42eab32)"
                  />
                  <path
                    d="M916.93 420.46a18.87 18.87 0 1 0-18.86 18.87 18.87 18.87 0 0 0 18.86-18.87z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#bfe2c5ac9-b028-4067-a7d9-394243c056ab)"
                  />
                  <path
                    d="M854.88 409.6a22.41 22.41 0 0 0 22.3-20.23 34.36 34.36 0 0 1 2.94-11.12c2.38-4.66 6.73-8.61 14.84-8.94h3.48a18.82 18.82 0 1 0-18.8-19.55 2 2 0 0 0 0 .29v1.04c-.24 6.5-5.09 16.37-21.11 14a23.15 23.15 0 0 0-3.65-.3 22.4 22.4 0 1 0 0 44.8z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#c5418ed93-eb74-4f42-85b6-2220c9f3c136)"
                  />
                  <path
                    d="M941.53 399.08a15.42 15.42 0 1 0-15.42-15.41 15.41 15.41 0 0 0 15.42 15.41z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#dc44b8900-9fa1-4aed-ad4a-7d214437f008)"
                  />
                  <path
                    d="M868.09 676.67v-1.75c.31-8.6 6.72-21.65 27.9-18.5a30.76 30.76 0 0 0 4.83.39 29.62 29.62 0 1 0-29.48-32.48c-.05.08-.58 9.67-5.29 17.11-3.62 5.73-10.23 9.93-21.59 9.39h-1.23a24.88 24.88 0 1 0 24.85 25.84z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#e280748a7-f50b-4ee2-a9d4-3fa8bc977d79)"
                  />
                  <path
                    d="M884 698.11a18.87 18.87 0 1 0 18.87-18.87A18.87 18.87 0 0 0 884 698.11z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#f14aed4a1-e5cc-4ba8-9a1c-fb6e9c081b96)"
                  />
                  <path
                    d="M946 709a22.41 22.41 0 0 0-22.3 20.23 34.36 34.36 0 0 1-2.94 11.12c-2.38 4.66-6.73 8.61-14.85 8.94H902.44a18.82 18.82 0 1 0 18.8 19.54 2 2 0 0 0 0-.29v-.44-.6c.24-6.5 5.09-16.37 21.11-14a23 23 0 0 0 3.65.3 22.4 22.4 0 1 0 0-44.8z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#g2a629427-9fd0-4501-b242-7b21ab2a8ae5)"
                  />
                  <path
                    d="M859.36 719.48a15.42 15.42 0 1 0 15.41 15.41 15.42 15.42 0 0 0-15.41-15.41z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#h34275c3b-fb7e-4c0f-8ff7-00e7000355ea)"
                  />
                  <path
                    d="M815 472.57s1.28.76 1.51.88c7.29 4.57 15.39 16.65 2.07 33.42a29.62 29.62 0 1 0 40.12-5.31c0-.08-8.08-5.34-12.18-13.14-3.14-6-3.48-13.82 2.67-23.39l.61-1.07a24.88 24.88 0 1 0-34.8 8.61z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#ibeeaf9ed-4de8-4c46-a7a1-3f19a49dc9f1)"
                  />
                  <path
                    d="M762.69 482.49a18.87 18.87 0 1 0 25.77-6.9 18.88 18.88 0 0 0-25.77 6.9z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#j144c78f7-517d-40dc-898b-e06f99208c31)"
                  />
                  <path
                    d="M708.61 472.55l.24.15.38.23.53.29c5.51 3.45 11.64 12.59 1.56 25.27a22.4 22.4 0 1 0 30.34-4 35 35 0 0 1-8.16-8.11c-2.84-4.39-4.09-10.13-.31-17.33.37-.72 1.25-2.2 1.25-2.2.17-.26.34-.53.5-.8a18.82 18.82 0 1 0-26.33 6.51z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#k63ab98a9-048b-4b48-85fa-03338ca4e446)"
                  />
                  <path
                    d="M761.19 449.24a15.41 15.41 0 1 0-5.64-21.05 15.41 15.41 0 0 0 5.64 21.05z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#la603b93d-9905-4b12-a9a5-9d03106621cf)"
                  />
                  <path
                    d="M985.93 646l-1.52-.88c-7.28-4.57-15.38-16.65-2.06-33.41a29 29 0 0 0 2.76-4 29.62 29.62 0 1 0-42.88 9.29c0 .08 8.08 5.34 12.17 13.14 3.15 6 3.49 13.82-2.66 23.39l-.62 1.07a24.88 24.88 0 1 0 34.81-8.6z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#m176ab860-5f03-4d0c-91eb-7466bd944204)"
                  />
                  <path
                    d="M1038.2 636.07a18.87 18.87 0 1 0-25.78 6.9 18.87 18.87 0 0 0 25.78-6.9z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#ne2f1fd25-707b-4d07-8094-8787981958b8)"
                  />
                  <path
                    d="M1092.28 646l-.25-.15-.37-.23-.53-.28c-5.51-3.46-11.64-12.59-1.56-25.28a22.4 22.4 0 1 0-30.34 4 34.52 34.52 0 0 1 8.15 8.1c2.85 4.39 4.09 10.13.32 17.33-.38.72-1.26 2.2-1.26 2.2-.17.26-.33.53-.49.8a18.82 18.82 0 1 0 26.33-6.51z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#o574ccb4e-ad65-481c-bdaf-bbde48a2a016)"
                  />
                  <path
                    d="M1039.69 669.32a15.41 15.41 0 1 0 5.64 21 15.42 15.42 0 0 0-5.64-21z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#p970b5e48-dec1-4eae-9fb1-fd1a51d0ae3c)"
                  />
                  <path
                    d="M856.63 619.22a29.62 29.62 0 0 0-27.07-52.61c-.09 0-8.66 4.33-17.46 4-6.77-.27-13.72-3.89-18.93-14l-.61-1.07a24.89 24.89 0 1 0-9.95 34.46l1.52-.87c7.6-4 22.11-5 30 14.91a29.61 29.61 0 0 0 42.53 15.22z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#qf2a7f529-feed-40eb-809c-129d39459d8d)"
                  />
                  <path
                    d="M772 614.41a18.87 18.87 0 1 0 25.78 6.91 18.88 18.88 0 0 0-25.78-6.91z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#rd9a98c62-fe62-4b2d-8cc5-90fd7eafc21d)"
                  />
                  <path
                    d="M764.93 664.39a34.45 34.45 0 0 1-11.1 3c-5.23.27-10.82-1.52-15.16-8.39-.44-.68-1.28-2.19-1.28-2.19-.14-.28-.29-.55-.45-.83a18.82 18.82 0 1 0-7.52 26.06l.25-.14.38-.21.52-.31c5.74-3 16.72-3.79 22.66 11.28a22.4 22.4 0 1 0 11.69-28.28z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#s875f79ee-498c-40be-a2f3-52f81d90f328)"
                  />
                  <path
                    d="M720.11 598.16a15.42 15.42 0 1 0 21.06 5.64 15.4 15.4 0 0 0-21.06-5.64z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#t3e4bc1d9-85f2-4e58-a4c1-f844871758a2)"
                  />
                  <path
                    d="M1008.33 563.05a24.88 24.88 0 1 0 9.95-34.45l-1.52.87c-7.6 4-22.11 5-30-14.91A29.62 29.62 0 1 0 971.32 552c.1 0 8.67-4.33 17.47-4 6.77.27 13.71 3.9 18.92 14z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#uca688990-b501-469f-b04e-6a418407b6c3)"
                  />
                  <path
                    d="M1028.91 504.15a18.87 18.87 0 1 0-25.77-6.91 18.87 18.87 0 0 0 25.77 6.91z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#v52a00656-3054-44df-95ac-76894af6788d)"
                  />
                  <path
                    d="M1036 454.17a34.63 34.63 0 0 1 11.1-3c5.22-.27 10.82 1.52 15.16 8.39.43.69 1.28 2.19 1.28 2.19.14.28.29.55.45.83a18.82 18.82 0 1 0 7.52-26.05l-.25.13-.39.21-.51.31c-5.75 3.05-16.72 3.79-22.67-11.28a21.66 21.66 0 0 0-1.57-3.31 22.4 22.4 0 1 0-10.12 31.58z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#wc1cc6832-f4c3-49c0-8f5a-87a2a941f53d)"
                  />
                  <path
                    d="M1065.36 493.71a15.41 15.41 0 1 0 21.06 5.64 15.4 15.4 0 0 0-21.06-5.64z"
                    transform="translate(-699.82 -331.69)"
                    fill="url(#xe8ee7b19-8b02-4204-9302-2936d2f16030)"
                  />
                </svg>
              </svg>
            </g>
            <g transform="translate(40.209999084472656,101.70822143554688)">
              <g fillRule="" className="tp-name">
                <g>
                  <path
                    d="M28.47-15.15L13.65-15.15L13.65 0L4.42 0L4.42-43.36L26.07-43.36Q31.98-43.36 34.52-42.51L34.52-42.51Q38.80-41.08 41.27-36.59L41.27-36.59Q43.09-33.22 43.09-29.25L43.09-29.25Q43.09-25.94 41.89-22.98Q40.69-20.02 38.55-18.14L38.55-18.14Q36.59-16.45 34.42-15.79Q32.24-15.15 28.47-15.15L28.47-15.15ZM13.65-35.62L13.65-22.88L26.20-22.88Q29.57-22.88 30.94-23.92L30.94-23.92Q32.95-25.55 32.95-29.57L32.95-29.57Q32.95-33.93 29.90-35.16L29.90-35.16Q28.80-35.62 26.39-35.62L26.39-35.62L13.65-35.62ZM58.56 0L58.56-35.62L44.78-35.62L44.78-43.36L82.16-43.36L82.16-35.62L67.80-35.62L67.80 0L58.56 0ZM110.89 0L86.91 0L86.91-43.36L111.41-43.36Q117.19-43.36 119.80-41.99L119.80-41.99Q122.85-40.50 124.41-36.98L124.41-36.98Q125.52-34.58 125.52-31.98L125.52-31.98Q125.52-28.60 123.73-25.68Q121.94-22.75 119.21-21.64L119.21-21.64Q125.52-18.79 125.52-11.44L125.52-11.44Q125.52-7.21 123.50-4.36L123.50-4.36Q121.75-1.89 119.02-0.94Q116.28 0 110.89 0L110.89 0ZM96.14-35.62L96.14-25.55L108.68-25.55Q110.69-25.55 111.57-25.74Q112.45-25.94 113.30-26.59L113.30-26.59Q115.31-28.14 115.31-30.88L115.31-30.88Q115.31-33.48 113.42-34.71L113.42-34.71Q112.06-35.62 108.88-35.62L108.88-35.62L96.14-35.62ZM96.14-17.81L96.14-7.74L108.68-7.74Q110.69-7.74 111.61-7.93Q112.52-8.13 113.30-8.78L113.30-8.78Q115.31-10.34 115.31-13.07L115.31-13.07Q115.31-15.67 113.42-16.90L113.42-16.90Q112.06-17.81 108.88-17.81L108.88-17.81L96.14-17.81ZM141.50 0L132.28 0L132.28-43.36L141.50-43.36L141.50 0Z"
                    transform="translate(-4.42, 43.355000000000004)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <mask id="dceccf35-5b4d-4cdf-861e-f7dd5cbd8798">
          <g fill="white">
            <rect
              fillOpacity="1"
              strokeWidth="2"
              x="0"
              y="0"
              width="217.5"
              height="246.73308411214953"
              className="image-rect"
            />
            <svg
              x="0"
              y="0"
              width="217.5"
              height="246.73308411214953"
              filtersec="colorsb6398240753"
              className="image-svg-svg primary"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 401.25 455.18"
              >
                <defs>
                  <linearGradient
                    id="ab93ca5e0-adea-403f-976e-9430d42eab32"
                    x1="1124.27"
                    y1="779"
                    x2="846.74"
                    y2="342.33"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="bfe2c5ac9-b028-4067-a7d9-394243c056ab"
                    x1="1125.45"
                    y1="778.24"
                    x2="847.93"
                    y2="341.57"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="c5418ed93-eb74-4f42-85b6-2220c9f3c136"
                    x1="1132.06"
                    y1="774.05"
                    x2="854.53"
                    y2="337.38"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="dc44b8900-9fa1-4aed-ad4a-7d214437f008"
                    x1="1173.07"
                    y1="747.98"
                    x2="895.54"
                    y2="311.31"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="e280748a7-f50b-4ee2-a9d4-3fa8bc977d79"
                    x1="1004.34"
                    y1="855.22"
                    x2="726.82"
                    y2="418.55"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="f14aed4a1-e5cc-4ba8-9a1c-fb6e9c081b96"
                    x1="1003.15"
                    y1="855.97"
                    x2="725.63"
                    y2="419.3"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="g2a629427-9fd0-4501-b242-7b21ab2a8ae5"
                    x1="996.55"
                    y1="860.17"
                    x2="719.03"
                    y2="423.5"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="h34275c3b-fb7e-4c0f-8ff7-00e7000355ea"
                    x1="955.54"
                    y1="886.23"
                    x2="678.02"
                    y2="449.56"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="ibeeaf9ed-4de8-4c46-a7a1-3f19a49dc9f1"
                    x1="1047.57"
                    y1="827.75"
                    x2="770.04"
                    y2="391.08"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="j144c78f7-517d-40dc-898b-e06f99208c31"
                    x1="1008.32"
                    y1="852.69"
                    x2="730.79"
                    y2="416.02"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="k63ab98a9-048b-4b48-85fa-03338ca4e446"
                    x1="970.63"
                    y1="876.64"
                    x2="693.11"
                    y2="439.97"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="la603b93d-9905-4b12-a9a5-9d03106621cf"
                    x1="1026.46"
                    y1="841.15"
                    x2="748.94"
                    y2="404.49"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="m176ab860-5f03-4d0c-91eb-7466bd944204"
                    x1="1081.04"
                    y1="806.47"
                    x2="803.52"
                    y2="369.8"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="ne2f1fd25-707b-4d07-8094-8787981958b8"
                    x1="1120.3"
                    y1="781.53"
                    x2="842.77"
                    y2="344.84"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="o574ccb4e-ad65-481c-bdaf-bbde48a2a016"
                    x1="1157.98"
                    y1="757.58"
                    x2="880.45"
                    y2="320.9"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="p970b5e48-dec1-4eae-9fb1-fd1a51d0ae3c"
                    x1="1102.15"
                    y1="793.06"
                    x2="824.61"
                    y2="356.38"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="qf2a7f529-feed-40eb-809c-129d39459d8d"
                    x1="989.6"
                    y1="864.58"
                    x2="712.08"
                    y2="427.91"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="rd9a98c62-fe62-4b2d-8cc5-90fd7eafc21d"
                    x1="947.17"
                    y1="891.57"
                    x2="669.63"
                    y2="454.88"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="s875f79ee-498c-40be-a2f3-52f81d90f328"
                    x1="904.4"
                    y1="918.74"
                    x2="626.87"
                    y2="482.07"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="t3e4bc1d9-85f2-4e58-a4c1-f844871758a2"
                    x1="917.7"
                    y1="910.28"
                    x2="640.18"
                    y2="473.61"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="uca688990-b501-469f-b04e-6a418407b6c3"
                    x1="1139.01"
                    y1="769.63"
                    x2="861.48"
                    y2="332.96"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="v52a00656-3054-44df-95ac-76894af6788d"
                    x1="1181.45"
                    y1="742.67"
                    x2="903.92"
                    y2="305.98"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="wc1cc6832-f4c3-49c0-8f5a-87a2a941f53d"
                    x1="1224.22"
                    y1="715.48"
                    x2="946.69"
                    y2="278.81"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                  <linearGradient
                    id="xe8ee7b19-8b02-4204-9302-2936d2f16030"
                    x1="1210.9"
                    y1="723.93"
                    x2="933.38"
                    y2="287.27"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#cce70b" />
                    <stop offset="1" stopColor="#00bcc6" />
                  </linearGradient>
                </defs>
                <path
                  d="M932.79 441.89v1.75c-.32 8.6-6.73 21.65-27.91 18.5a29.61 29.61 0 1 0 24.66 32.08c0-.08.58-9.67 5.29-17.11 3.62-5.72 10.23-9.93 21.59-9.39h1.23a24.88 24.88 0 1 0-24.86-25.84z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M916.93 420.46a18.87 18.87 0 1 0-18.86 18.87 18.87 18.87 0 0 0 18.86-18.87z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M854.88 409.6a22.41 22.41 0 0 0 22.3-20.23 34.36 34.36 0 0 1 2.94-11.12c2.38-4.66 6.73-8.61 14.84-8.94h3.48a18.82 18.82 0 1 0-18.8-19.55 2 2 0 0 0 0 .29v1.04c-.24 6.5-5.09 16.37-21.11 14a23.15 23.15 0 0 0-3.65-.3 22.4 22.4 0 1 0 0 44.8z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M941.53 399.08a15.42 15.42 0 1 0-15.42-15.41 15.41 15.41 0 0 0 15.42 15.41z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M868.09 676.67v-1.75c.31-8.6 6.72-21.65 27.9-18.5a30.76 30.76 0 0 0 4.83.39 29.62 29.62 0 1 0-29.48-32.48c-.05.08-.58 9.67-5.29 17.11-3.62 5.73-10.23 9.93-21.59 9.39h-1.23a24.88 24.88 0 1 0 24.85 25.84z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M884 698.11a18.87 18.87 0 1 0 18.87-18.87A18.87 18.87 0 0 0 884 698.11z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M946 709a22.41 22.41 0 0 0-22.3 20.23 34.36 34.36 0 0 1-2.94 11.12c-2.38 4.66-6.73 8.61-14.85 8.94H902.44a18.82 18.82 0 1 0 18.8 19.54 2 2 0 0 0 0-.29v-.44-.6c.24-6.5 5.09-16.37 21.11-14a23 23 0 0 0 3.65.3 22.4 22.4 0 1 0 0-44.8z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M859.36 719.48a15.42 15.42 0 1 0 15.41 15.41 15.42 15.42 0 0 0-15.41-15.41z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M815 472.57s1.28.76 1.51.88c7.29 4.57 15.39 16.65 2.07 33.42a29.62 29.62 0 1 0 40.12-5.31c0-.08-8.08-5.34-12.18-13.14-3.14-6-3.48-13.82 2.67-23.39l.61-1.07a24.88 24.88 0 1 0-34.8 8.61z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M762.69 482.49a18.87 18.87 0 1 0 25.77-6.9 18.88 18.88 0 0 0-25.77 6.9z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M708.61 472.55l.24.15.38.23.53.29c5.51 3.45 11.64 12.59 1.56 25.27a22.4 22.4 0 1 0 30.34-4 35 35 0 0 1-8.16-8.11c-2.84-4.39-4.09-10.13-.31-17.33.37-.72 1.25-2.2 1.25-2.2.17-.26.34-.53.5-.8a18.82 18.82 0 1 0-26.33 6.51z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M761.19 449.24a15.41 15.41 0 1 0-5.64-21.05 15.41 15.41 0 0 0 5.64 21.05z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M985.93 646l-1.52-.88c-7.28-4.57-15.38-16.65-2.06-33.41a29 29 0 0 0 2.76-4 29.62 29.62 0 1 0-42.88 9.29c0 .08 8.08 5.34 12.17 13.14 3.15 6 3.49 13.82-2.66 23.39l-.62 1.07a24.88 24.88 0 1 0 34.81-8.6z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M1038.2 636.07a18.87 18.87 0 1 0-25.78 6.9 18.87 18.87 0 0 0 25.78-6.9z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M1092.28 646l-.25-.15-.37-.23-.53-.28c-5.51-3.46-11.64-12.59-1.56-25.28a22.4 22.4 0 1 0-30.34 4 34.52 34.52 0 0 1 8.15 8.1c2.85 4.39 4.09 10.13.32 17.33-.38.72-1.26 2.2-1.26 2.2-.17.26-.33.53-.49.8a18.82 18.82 0 1 0 26.33-6.51z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M1039.69 669.32a15.41 15.41 0 1 0 5.64 21 15.42 15.42 0 0 0-5.64-21z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M856.63 619.22a29.62 29.62 0 0 0-27.07-52.61c-.09 0-8.66 4.33-17.46 4-6.77-.27-13.72-3.89-18.93-14l-.61-1.07a24.89 24.89 0 1 0-9.95 34.46l1.52-.87c7.6-4 22.11-5 30 14.91a29.61 29.61 0 0 0 42.53 15.22z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M772 614.41a18.87 18.87 0 1 0 25.78 6.91 18.88 18.88 0 0 0-25.78-6.91z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M764.93 664.39a34.45 34.45 0 0 1-11.1 3c-5.23.27-10.82-1.52-15.16-8.39-.44-.68-1.28-2.19-1.28-2.19-.14-.28-.29-.55-.45-.83a18.82 18.82 0 1 0-7.52 26.06l.25-.14.38-.21.52-.31c5.74-3 16.72-3.79 22.66 11.28a22.4 22.4 0 1 0 11.69-28.28z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M720.11 598.16a15.42 15.42 0 1 0 21.06 5.64 15.4 15.4 0 0 0-21.06-5.64z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M1008.33 563.05a24.88 24.88 0 1 0 9.95-34.45l-1.52.87c-7.6 4-22.11 5-30-14.91A29.62 29.62 0 1 0 971.32 552c.1 0 8.67-4.33 17.47-4 6.77.27 13.71 3.9 18.92 14z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M1028.91 504.15a18.87 18.87 0 1 0-25.77-6.91 18.87 18.87 0 0 0 25.77 6.91z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M1036 454.17a34.63 34.63 0 0 1 11.1-3c5.22-.27 10.82 1.52 15.16 8.39.43.69 1.28 2.19 1.28 2.19.14.28.29.55.45.83a18.82 18.82 0 1 0 7.52-26.05l-.25.13-.39.21-.51.31c-5.75 3.05-16.72 3.79-22.67-11.28a21.66 21.66 0 0 0-1.57-3.31 22.4 22.4 0 1 0-10.12 31.58z"
                  transform="translate(-699.82 -331.69)"
                />
                <path
                  d="M1065.36 493.71a15.41 15.41 0 1 0 21.06 5.64 15.4 15.4 0 0 0-21.06-5.64z"
                  transform="translate(-699.82 -331.69)"
                />
              </svg>
            </svg>
          </g>
          <g
            transform="translate(40.209999084472656,101.70822143554688)"
            fill="black"
            stroke="#000"
            strokeWidth="6"
            strokeLinejoin="round"
          >
            <g fillRule="" className="tp-name">
              <g>
                <path
                  d="M28.47-15.15L13.65-15.15L13.65 0L4.42 0L4.42-43.36L26.07-43.36Q31.98-43.36 34.52-42.51L34.52-42.51Q38.80-41.08 41.27-36.59L41.27-36.59Q43.09-33.22 43.09-29.25L43.09-29.25Q43.09-25.94 41.89-22.98Q40.69-20.02 38.55-18.14L38.55-18.14Q36.59-16.45 34.42-15.79Q32.24-15.15 28.47-15.15L28.47-15.15ZM13.65-35.62L13.65-22.88L26.20-22.88Q29.57-22.88 30.94-23.92L30.94-23.92Q32.95-25.55 32.95-29.57L32.95-29.57Q32.95-33.93 29.90-35.16L29.90-35.16Q28.80-35.62 26.39-35.62L26.39-35.62L13.65-35.62ZM58.56 0L58.56-35.62L44.78-35.62L44.78-43.36L82.16-43.36L82.16-35.62L67.80-35.62L67.80 0L58.56 0ZM110.89 0L86.91 0L86.91-43.36L111.41-43.36Q117.19-43.36 119.80-41.99L119.80-41.99Q122.85-40.50 124.41-36.98L124.41-36.98Q125.52-34.58 125.52-31.98L125.52-31.98Q125.52-28.60 123.73-25.68Q121.94-22.75 119.21-21.64L119.21-21.64Q125.52-18.79 125.52-11.44L125.52-11.44Q125.52-7.21 123.50-4.36L123.50-4.36Q121.75-1.89 119.02-0.94Q116.28 0 110.89 0L110.89 0ZM96.14-35.62L96.14-25.55L108.68-25.55Q110.69-25.55 111.57-25.74Q112.45-25.94 113.30-26.59L113.30-26.59Q115.31-28.14 115.31-30.88L115.31-30.88Q115.31-33.48 113.42-34.71L113.42-34.71Q112.06-35.62 108.88-35.62L108.88-35.62L96.14-35.62ZM96.14-17.81L96.14-7.74L108.68-7.74Q110.69-7.74 111.61-7.93Q112.52-8.13 113.30-8.78L113.30-8.78Q115.31-10.34 115.31-13.07L115.31-13.07Q115.31-15.67 113.42-16.90L113.42-16.90Q112.06-17.81 108.88-17.81L108.88-17.81L96.14-17.81ZM141.50 0L132.28 0L132.28-43.36L141.50-43.36L141.50 0Z"
                  transform="translate(-4.42, 43.355000000000004)"
                />
              </g>
            </g>
          </g>
        </mask>
      </svg>
    );
  }
}

export default function LogoIcon(props) {
  const [Component = DefaultLogoIcon] = PLUGIN_LOGO_ICON_COMPONENTS;
  return <Component {...props} />;
}
