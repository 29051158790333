import styled from "@emotion/styled";

export const PaneContent = styled.div`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;
import styled from "@emotion/styled";

export const PaneContent = styled.div`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;
