import styled from "@emotion/styled";

export const InfoText = styled.div`
  color: var(--mb-color-text-medium);
`;
import styled from "@emotion/styled";

export const InfoText = styled.div`
  color: var(--mb-color-text-medium);
`;
