export const APP_BAR_HEIGHT = "52px";
export const APP_SUBHEADER_HEIGHT = "48px";
export const APP_BAR_EXTENDED_HEIGHT = "98px";
export const ADMIN_NAVBAR_HEIGHT = "65px";
export const NAV_SIDEBAR_WIDTH = "324px";
export const APP_BAR_HEIGHT = "52px";
export const APP_SUBHEADER_HEIGHT = "48px";
export const APP_BAR_EXTENDED_HEIGHT = "98px";
export const ADMIN_NAVBAR_HEIGHT = "65px";
export const NAV_SIDEBAR_WIDTH = "324px";
