import styled from "@emotion/styled";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
import styled from "@emotion/styled";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
