import styled from "@emotion/styled";

export const UpsellContent = styled.div`
  padding: 1rem 1.5rem;
  line-height: 24px;
  max-width: 344px;
`;
import styled from "@emotion/styled";

export const UpsellContent = styled.div`
  padding: 1rem 1.5rem;
  line-height: 24px;
  max-width: 344px;
`;
