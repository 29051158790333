import styled from "@emotion/styled";

import InputBlurChange from "metabase/components/InputBlurChange";

export const NumericInputBlurChange = styled(InputBlurChange)`
  width: auto;
`;
import styled from "@emotion/styled";

import InputBlurChange from "metabase/components/InputBlurChange";

export const NumericInputBlurChange = styled(InputBlurChange)`
  width: auto;
`;
