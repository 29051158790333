import styled from "@emotion/styled";

export const EntityViewSwitchRoot = styled.div`
  padding: 0.25rem 0;
`;
import styled from "@emotion/styled";

export const EntityViewSwitchRoot = styled.div`
  padding: 0.25rem 0;
`;
