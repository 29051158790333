import styled from "@emotion/styled";

import { Icon } from "metabase/ui";

export const ClearIcon = styled(Icon)`
  color: var(--mb-color-brand);
`;
import styled from "@emotion/styled";

import { Icon } from "metabase/ui";

export const ClearIcon = styled(Icon)`
  color: var(--mb-color-brand);
`;
