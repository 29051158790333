import styled from "@emotion/styled";

import InputBlurChange from "metabase/components/InputBlurChange";

export const SettingInputBlurChange = styled(InputBlurChange)`
  width: 400px;
`;
import styled from "@emotion/styled";

import InputBlurChange from "metabase/components/InputBlurChange";

export const SettingInputBlurChange = styled(InputBlurChange)`
  width: 400px;
`;
