import styled from "@emotion/styled";

export const ToastRoot = styled.div`
  display: flex;
  align-items: center;
`;
import styled from "@emotion/styled";

export const ToastRoot = styled.div`
  display: flex;
  align-items: center;
`;
