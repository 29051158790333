import styled from "@emotion/styled";

export const LeftNavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 266px;
  flex-shrink: 0;
`;
import styled from "@emotion/styled";

export const LeftNavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 266px;
  flex-shrink: 0;
`;
