import styled from "@emotion/styled";

import { Tabs } from "metabase/ui";

export const TabList = styled(Tabs.List)`
  border-bottom-color: transparent;
`;
import styled from "@emotion/styled";

import { Tabs } from "metabase/ui";

export const TabList = styled(Tabs.List)`
  border-bottom-color: transparent;
`;
