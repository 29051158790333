import styled from "@emotion/styled";

export const PermissionTableContainer = styled.div`
  overflow: auto;
  height: 50vh;
`;
import styled from "@emotion/styled";

export const PermissionTableContainer = styled.div`
  overflow: auto;
  height: 50vh;
`;
