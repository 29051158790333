import { SettingInput } from "./SettingInput";

const SettingPassword = props => <SettingInput {...props} type="password" />;

export default SettingPassword;
import { SettingInput } from "./SettingInput";

const SettingPassword = props => <SettingInput {...props} type="password" />;

export default SettingPassword;
