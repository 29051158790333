import styled from "@emotion/styled";

export const ActionFormButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
`;
import styled from "@emotion/styled";

export const ActionFormButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
`;
