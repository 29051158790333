export const USER_STATUS = {
  active: "active",
  deactivated: "deactivated",
};

export const ACTIVE_USERS_NUDGE_THRESHOLD = 50;
export const USER_STATUS = {
  active: "active",
  deactivated: "deactivated",
};

export const ACTIVE_USERS_NUDGE_THRESHOLD = 50;
