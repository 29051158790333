import styled from "@emotion/styled";

export const AccordionListRoot = styled.div`
  outline: none;
  overflow: auto;
`;
import styled from "@emotion/styled";

export const AccordionListRoot = styled.div`
  outline: none;
  overflow: auto;
`;
