import styled from "@emotion/styled";

export const DataSelectorSchemaPickerContainer = styled.div`
  width: 300px;
`;
import styled from "@emotion/styled";

export const DataSelectorSchemaPickerContainer = styled.div`
  width: 300px;
`;
