import styled from "@emotion/styled";

export const AddAnotherContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
`;
import styled from "@emotion/styled";

export const AddAnotherContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
`;
