import styled from "@emotion/styled";

export const PopoverRoot = styled.div`
  padding-top: 1.5rem;
  max-height: 600px;
  overflow-y: auto;
`;
import styled from "@emotion/styled";

export const PopoverRoot = styled.div`
  padding-top: 1.5rem;
  max-height: 600px;
  overflow-y: auto;
`;
