export function pulse(pulseId: number) {
  return `/pulse/${pulseId}`;
}

export function pulseEdit(pulseId: number) {
  return `/pulse/${pulseId}`;
}
export function pulse(pulseId: number) {
  return `/pulse/${pulseId}`;
}

export function pulseEdit(pulseId: number) {
  return `/pulse/${pulseId}`;
}
