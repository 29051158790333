import styled from "@emotion/styled";

export const AdminGroupLabel = styled.span`
  color: var(--mb-color-filter);
`;
import styled from "@emotion/styled";

export const AdminGroupLabel = styled.span`
  color: var(--mb-color-filter);
`;
