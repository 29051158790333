import styled from "@emotion/styled";

export const StepDescription = styled.div`
  color: var(--mb-color-text-medium);
  margin-top: 0.875rem;
`;
import styled from "@emotion/styled";

export const StepDescription = styled.div`
  color: var(--mb-color-text-medium);
  margin-top: 0.875rem;
`;
