import Popover from "metabase/components/Popover";
import Triggerable from "metabase/components/Triggerable";

/**
 * @deprecated prefer Popover from "metabase/ui" + useState instead
 */
export default Triggerable(Popover);
import Popover from "metabase/components/Popover";
import Triggerable from "metabase/components/Triggerable";

/**
 * @deprecated prefer Popover from "metabase/ui" + useState instead
 */
export default Triggerable(Popover);
