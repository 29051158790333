import styled from "@emotion/styled";

export const StepDescription = styled.div`
  margin: 0.875rem 0 2rem;
  color: var(--mb-color-text-medium);
`;
import styled from "@emotion/styled";

export const StepDescription = styled.div`
  margin: 0.875rem 0 2rem;
  color: var(--mb-color-text-medium);
`;
