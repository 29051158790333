function PluginPlaceholder() {
  return null;
}

export default PluginPlaceholder;
function PluginPlaceholder() {
  return null;
}

export default PluginPlaceholder;
